//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  components: {
    TaskDetailM: () => import("./taskdetail-modal"),
    MakeUp: () => import("./makeup-card"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    taskArray: {
      type: Array,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    selMem: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selTask: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },

  methods: {
    /**
     * 卡片点击
     * type:1 任务详情弹窗  2补交任务弹窗
     */
    handleTask(val, type) {
      this.selTask = val;
      if (this.user.UsId != this.selTask.UserId && type == 2) {
        return;
      }
      if (type == 1) {
        this.$modal.show("taskdetailM");
      } else if (type == 2) {
        this.$modal.show("makeup");
      }
    },
    deleteTask(val) {
      this.selTask = val;
      if (this.user.UsId != this.selTask.UserId) {
        return;
      }
      const data = {
        teamId: this.teamValue,
        Id: this.selTask.Id,
      };
      this.$http.post("/Task/DelTaskHistory.ashx", data).then((resp) => {
        if (resp.res == 0) {
          //刷新任务卡片
          this.$emit("loadData");
        }
      });
    },
  },
};
